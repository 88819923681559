<template>
  <div>
    <el-table :data="tableData.list" border style="width: 100%">
      <el-table-column prop="ip" label="ip"> </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column prop="headerUrl" label="头像" width="80">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            :size="50"
            :src="scope.row.headerUrl"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="等级" width="50"> </el-table-column>
      <el-table-column prop="browser" label="浏览器"> </el-table-column>
      <el-table-column prop="system" label="系统"> </el-table-column>
      <el-table-column prop="insertTime" label="加入时间"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
      <el-table-column prop="content" label="内容"> </el-table-column>
      <el-table-column prop="replyContent" label="回复内容">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.isReply === 1 ? scope.row.replyContent : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="edit(scope)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="rep(scope)" type="text" size="small"
            >回复</el-button
          >
          <el-button @click="del(scope)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :small="this.$store.getters.isPCM ? true : false"
      background
      layout="prev, pager, next ,jumper"
      :total="tableData.total"
      :page-size="10"
      @current-change="changePage"
    >
    </el-pagination>

    <!-- 编辑 -->
    <el-drawer
      title="留言编辑"
      :visible.sync="drawer"
      direction="ltr"
      :size="this.$store.getters.isPCM ? '80%' : '70%'"
    >
      <div>
        <el-form
          :model="form"
          :label-position="'top'"
          style="width: 90%; margin: 0 auto"
        >
          <el-form-item label="ip">
            <el-input v-model="form.ip"></el-input>
          </el-form-item>
          <el-form-item label="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="headerUrl">
            <el-input v-model="form.headerUrl"></el-input>
          </el-form-item>
          <el-form-item label="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>

          <el-form-item label="grade">
            <el-input v-model="form.grade"></el-input>
          </el-form-item>

          <el-form-item label="browser">
            <el-input v-model="form.browser"></el-input>
          </el-form-item>

          <el-form-item label="system">
            <el-input v-model="form.system"></el-input>
          </el-form-item>

          <el-form-item label="insertTime">
            <el-date-picker
              v-model="form.insertTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="留言">
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>

          <el-form-item label="是否回复">
            <el-switch v-model="form.isReply"></el-switch>
          </el-form-item>

          <el-form-item label="回复内容" v-if="form.isReply">
            <el-input type="textarea" v-model="form.replyContent"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="subEdit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <!-- 回复 -->
    <el-dialog title="回复:" :visible.sync="reply">
      <el-form :model="form">
        <el-form-item label="留言内容">
          <el-input type="textarea" v-model="form.content" readOnly></el-input>
        </el-form-item>

        <el-form-item label="是否回邮件">
          <el-switch v-model="form.isReplyES"></el-switch>
        </el-form-item>

        <el-form-item label="回复内容">
          <el-input type="textarea" v-model="form.replyContent"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="reply = false">取 消</el-button>
        <el-button type="primary" @click="subReq">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      form: {
        isReplyE: false,
        isReplyES: false,
      },
      reply: false,
      // replyContent:{},
      tableData: [],
    };
  },
  created() {
    this.getData(1, 10);
  },
  methods: {
    changePage(curr) {
      this.getData(curr, 10);
    },
    getData(curr, size) {
      let that = this;
      this.$axios
        .get("/adminApi/comments", {
          params: {
            curr: curr,
            size: size,
          },
        })
        .then(function (resp) {
          resp.data.data.list.forEach((element) => {
            element.insertTime = that.common.dateFormatter(element.insertTime);
          });
          that.tableData = resp.data.data;
        });
    },
    edit(scope) {
      //虚拟变量 转换true
      scope.row.isReplyE = scope.row.isReply === 1;
      scope.row.insertTime = new Date(scope.row.insertTime);
      this.form = scope.row;
      this.drawer = true;
    },
    subEdit() {
      this.form.isReply = this.form.isReplyE ? 1 : 0;
      //提交时 删除 虚拟变量 防报错
      delete this.form.isReplyE;
      this.$axios
        .post("/adminApi/editComment", this.form)
        .then(function (resp) {
          if (resp.data.code === 200) {
            location.reload();
          }
        });
    },
    rep(scope) {
      if (scope.row.isReply === 1) {
        this.$message({
          type: "error",
          message: "该留言已回复！",
        });
        return;
      }
      this.form = scope.row;
      //开启回复输入弹框
      this.reply = true;
    },
    subReq() {
      this.form.isReply = this.form.isReplyE ? 1 : 0;
      this.form.insertTime = new Date(this.form.insertTime);
      //是否回邮件 虚拟值
      let isReplyES = this.form.isReplyES ? 1 : 0;
      delete this.form.isReplyE;
      delete this.form.isReplyES;
      let that = this;
      this.$axios
        .post("/adminApi/replyComment?isReplyEmail=" + isReplyES, this.form)
        .then(function (resp) {
          that.$message({
            type: "success",
            message: resp.data.msg,
          });
        });
      that.reply = false;
    },
    del(scope) {
      this.$confirm("是否确定删除留言：", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$message({
            type: "info",
            message: "已删除！",
          });
          let that = this;
          this.$axios
            .get("/adminApi/delComment?id=" + scope.row.id)
            .then(function () {
              that.getData(1, 15);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>